import { AlertButton } from "@ionic/angular";

export const IVORY_COST_PREFIX_1 = "00225";
export const IVORY_COST_PREFIX_2 = "+225";
export const IVORY_COST_PREFIX = "225";
export const MOOV_PREFIX = "01";
export const MTN_PREFIX = "05";
export const ORANGE_PREFIX = "07";

export const AlertOK: AlertButton = {
  text: "Accepter",
  cssClass: "yes",
};

export const AlertNo: AlertButton = {
  text: "Refuser",
  role: "cancel",
  cssClass: "no",
};

/*
Has minimum 8 characters in length. Adjust it by modifying {8,}
At least one uppercase English letter. You can remove this condition by removing (?=.*?[A-Z])
At least one lowercase English letter.  You can remove this condition by removing (?=.*?[a-z])
At least one digit. You can remove this condition by removing (?=.*?[0-9])
*/
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

export const DELETE_USER_AFTER_DAYS = 15;
